// import Nav from "./components/nav/Nav";
// import IntroBanner from './components/intro-banner/IntroBanner';
import underConstruction from "./assets/images/under-construction.jpg";

function App() {
    return (
      //             <Nav></Nav>
      // <IntroBanner></IntroBanner>
        <div className="container">
            <div className="row">
                <div className="mt-5 col-12 d-flex justify-content-center">
                    <img src={underConstruction} alt="" />
                </div>
                <div className="mt-5 col-12 d-flex justify-content-center">
                    <h1>Until then...</h1>
                    <a href="https://www.twitch.tv/scardor">
                        <button className="px-2 py-1">Let's Hang Out On Stream</button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
